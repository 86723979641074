/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.12.4/dist/jquery.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 * - /npm/slick-carousel@1.6.0/slick/slick.min.js
 * - /npm/pickadate@3.5.6/lib/picker.min.js
 * - /npm/pickadate@3.5.6/lib/picker.date.min.js
 * - /npm/pickadate@3.5.6/lib/picker.time.min.js
 * - /npm/cookieconsent@3.0.4/build/cookieconsent.min.js
 * - /npm/jquery-lazyload@1.9.7/jquery.lazyload.min.js
 * - /npm/screenfull@3.3.1/dist/screenfull.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
